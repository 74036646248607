// Projects.js
import React, {useState} from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Projects.css';
import proj1 from '../assets/projects/portfolio-1.jpg';
import proj2 from '../assets/projects/portfolio-2.jpg';
import proj3 from '../assets/projects/portfolio-3.jpg';
import proj4 from '../assets/projects/portfolio-4.jpg';
import proj5 from '../assets/projects/portfolio-5.jpg';
import proj6 from '../assets/projects/portfolio-6.jpg';
import proj7 from '../assets/projects/portfolio-7.jpg';
import proj8 from '../assets/projects/portfolio-8.jpg';
import proj9 from '../assets/projects/portfolio-9.jpg';
import proj10 from '../assets/projects/portfolio-10.jpg';
import proj11 from '../assets/projects/portfolio-11.jpg';
import proj12 from '../assets/projects/portfolio-12.jpg';
import proj13 from '../assets/projects/portfolio-13.jpg';
import proj14 from '../assets/projects/portfolio-14.jpg';
import proj15 from '../assets/projects/portfolio-15.jpg';
import proj16 from '../assets/projects/portfolio-16.jpg';
import proj17 from '../assets/projects/portfolio-17.jpg';
import proj18 from '../assets/projects/portfolio-18.jpg';
import proj19 from '../assets/projects/portfolio-19.jpg';
import proj20 from '../assets/projects/portfolio-20.jpg';
import proj21 from '../assets/projects/portfolio-21.jpg';
import proj22 from '../assets/projects/portfolio-22.jpg';
import proj23 from '../assets/projects/portfolio-23.jpg';
import proj24 from '../assets/projects/portfolio-24.jpg';

import port1img1 from '../assets/projects/port1-img1.jpg'
import port1img2 from '../assets/projects/port1-img2.jpg'

import port2img1 from '../assets/projects/port2-img1.jpg'
import port2img2 from '../assets/projects/port2-img2.jpg'
import port2img3 from '../assets/projects/port2-img3.jpg'
import port2img4 from '../assets/projects/port2-img4.jpg'

import port3img1 from '../assets/projects/port3-img1.jpg'

import port9img1 from '../assets/projects/port9-img1.jpg'
import port9img2 from '../assets/projects/port9-img2.jpg'

import port11img1 from '../assets/projects/port11-img1.jpg'

import port12img1 from '../assets/projects/port12-img1.jpg'

const projectData = [
  { id: 24, 
    title: 'Lucid', 
    description: 'Wireframe to code with LLMs', 
    images: [proj24, port1img1, port1img2] 
  },
  { 
    id: 23, 
    title: 'QuantumViz', 
    description: 'Quantum circuit designer using LLM + RAG + automatic web surfer that scrapes circuit diagrams off papers on arXiv and converts them to IBM Qiskit code', 
    images: [proj23, port2img1, port2img2, port2img3, port2img4] 
  },
  { id: 22, 
    title: 'VidMorph', 
    description: 'AI-advertisement platform with face swap, voice style transfer, speech synthesis, and lip-sync', 
    images: [proj22, port3img1] 
  },
  { id: 21, title: 'Greenify', description: '3D asset generation using LumaAI for city planning', images: [proj21] },
  { id: 20, title: 'Getaway Guru', description: 'One stop shop for travel planning with automatic plane ticket and airbnb booking and destination planning', images: [proj20] },
  { id: 19, title: 'Spotify Clone', description: 'Clone of Spotify music platform', images: [proj19] },
  { id: 18, title: 'Netflix Clone', description: 'Clone of Netflix movie streaming platform', images: [proj18] },
  { id: 17, title: 'Robinhood Clone', description: 'Clone of Robinhood investing platform', images: [proj17] },
  {
    id: 16,
    title: 'Auto-LaTeX Equations',
    description: `Math and Physics LaTeX equation renderer for Google Slides.<br />
                  <a href="https://workspace.google.com/marketplace/app/autolatex_equations/850293439076" target="_blank" style="color: #1E90FF;">30M+ downloads on Google Workspace Marketplace</a>`,
    images: [proj16, port9img1, port9img2]
  },
  { id: 15, title: 'Rideshare', description: 'Split Ubers and Lyfts with your friends', images: [proj15] },
  { id: 14, 
    title: 'Archiscape', 
    description: '2D floorplans to 3D models and virtual tours', 
    images: [proj14, port11img1] 
  },
  { id: 13, 
    title: 'TopDoc', 
    description: 
    'Telehealth diagnosis platform with medical imaging algorithms for glaucoma, brain tumors, and tuberculosis', 
    images: [proj13, port12img1] 
  },
  { id: 12, title: 'Ugli', description: 'Imperfect produce routing platform delivering directly from farms to restaurant owners', images: [proj12] },
  { id: 11, title: 'ForgetMeNot', description: "Face recognition app to help Alzheimer's patients recognize family members", images: [proj11] },
  { id: 10, title: 'Bookworm', description: 'NLP summary app for summarizing literature novels and generating study guides', images: [proj10] },
  { id: 9, title: 'Harvest', description: 'Crop recommender based on soil element content, weather, and geography to improve farm yield and reduce soil erosion', images: [proj9] },
  { id: 8, title: 'BikeHail', description: 'Rideshare for bikes', images: [proj8] },
  { id: 7, title: 'Universal Speech', description: 'NLP translation app', images: [proj7] },
  { id: 6, title: 'Rize', description: 'Automatic EHR record generator based on online calls with doctors', images: [proj6] },
  { id: 5, title: 'AWS Deepracer', description: 'RL for self driving', images: [proj5] },
  { id: 4, title: "2021 FRC Robot 'Guppy'", description: 'FRC INFINITE RECHARGE 2020-2021', images: [proj4] },
  { id: 3, title: '2020 FRC Robot', description: 'FRC INFINITE RECHARGE 2019-2020', images: [proj3] },
  { id: 2, title: '2019 FTC Robot', description: 'FTC ROVER RUCKUS 2018-2019', images: [proj2] },
  { id: 1, title: 'Self-Driving Car', description: 'YOLOv3 Model for object detection from static images and live video', images: [proj1] },
];


function Projects() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeProject, setActiveProject] = useState(null);

  const openModal = (project) => {
    setActiveProject(project);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setActiveProject(null);
  };

  return (
    <div className="projects">
      <h2>My Projects</h2>
      <div className="project-grid">
        {projectData.map((project) => (
          <div 
            key={project.id} 
            className="project-card" 
            onClick={() => openModal(project)}
          >
            <img src={project.images[0]} alt={project.title} className="project-image" />
            <div className="overlay">
              <h3 className="project-title">{project.title}</h3>
            </div>
          </div>
        ))}
      </div>

      {/* Modal */}
      {isModalOpen && activeProject && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>{activeProject.title}</h2>
            <div 
              className="project-description" 
              dangerouslySetInnerHTML={{ __html: activeProject.description }} 
            />
            <Carousel>
              {activeProject.images.map((image, index) => (
                <div key={index}>
                  <img src={image} alt={`${activeProject.title} ${index + 1}`} />
                </div>
              ))}
            </Carousel>
            <button className="close-button" onClick={closeModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Projects;